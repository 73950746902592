<template>
  <div class="container ma-tb-20">
    <car-state
      ref="carState"
      :phone="detail.CallBox"
      :isMap="isMap"
      show="true"
      :taskId="taskId"
      :taskStates="detail.TaskInfo.Status"
    ></car-state>
    <div class="bg-fff setting-index">
      <back-button msg="地图"></back-button>

      <div class="ma-t-20 relative">
        <div id="container" style="width: 100%; height: 480px"></div>
        <div class="loading-box" v-if="centerLon == 0 || centerLat == 0">
          <van-loading color="#58AFDA" size="80" vertical>加载中...</van-loading>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let _this;
import AMap from "AMap"; // 引入高德地图

export default {
  data() {
    return {
      show: false,
      isMap: true,
      taskId: "",
      detail: {
        TaskInfo: "",
        MedicalAdvice: [],
        Member: "",
        LogList: [],
        CallBox: "",
      },
      insertTime: "",
      content: "",
      voicePath: "",
      showMap: false,
      oncde: 0,
      map: "",
      loadDataIntervals: null,
      PolylinePath: [],
      centerLon: 0,
      centerLat: 0
    };
  },
  created() {
    _this = this;
    console.log("mouted");
    _this.taskId = _this.$route.query.id;
  },

  mounted() {
    console.log("mouted");
    _this.$refs.carState.isShow = true;
    _this.init();
    _this.loadDataIntervals = setInterval(() => {
      _this.init();
    }, 1000 * 20);
  },
  watch: {
    $route(to, from) {},
  },

  //监听当前页面返回事件
  beforeRouteLeave(to, from, next) {
    //   //next方法传true或者不传为默认历史返回，传false为不执行历史回退
    clearInterval(_this.loadDataIntervals);
    next();
  },
  methods: {
    async init() {
      //获取救护车的位置

      _this.mapList = await _this.$request(
        "/Ambulance/v1/Location/Get?taskId=" + _this.taskId,
        {},
        false,
        true
      );
      _this.mapList = _this.mapList.Data;
      if (_this.mapList.PolylinePath.length > 0) {
        _this.mapList.PolylinePath.forEach((e, v) => {
          _this.mapList.PolylinePath[v] = JSON.parse(e);
        });
        console.log(_this.mapList.PolylinePath);
        _this.centerLon =
          _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][0];
        _this.centerLat =
          _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][1];
      } else {
        _this.centerLon = _this.mapList.Hospital.Longitude;
        _this.centerLat = _this.mapList.Hospital.Latitude;
      }

      _this.getMap();
      _this.once = 1;
    },

    getMap() {
      if (!_this.once) {
        _this.map = new AMap.Map("container", {
          resizeEnable: true,
          center: [_this.centerLon, _this.centerLat], //地图中心点
          zoom: 16, //地图显示的缩放级别
        });
      }

      if (_this.mapList.PolylinePath.length > 0) {
          _this.map
        AMap.convertFrom(
          _this.mapList.PolylinePath,
          "gps",
          function (status, result) {
            if (result.info === "ok") {
              _this.map.setZoomAndCenter(_this.map.getZoom(), [
                result.locations[result.locations.length - 1].KL,
                result.locations[result.locations.length - 1].kT,
              ]);
               _this.PolylinePath = [];
              result.locations.forEach((v, e) => {
                _this.PolylinePath.push([v.KL, v.kT]);
              });
              console.log(_this.PolylinePath);
              _this.path = new AMap.Polyline({
                path: _this.PolylinePath,
                isOutline: false, //线条是否带描边，默认false
                outlineColor: "#ffeeff", //线条描边颜色，此项仅在isOutline为true时有效，默认：#000000
                borderWeight: 1, //描边的宽度，默认为1
                strokeColor: "#3366FF", //线条颜色，使用16进制颜色代码赋值。默认值为#006600
                strokeOpacity: 1, //线条透明度，取值范围[0,1]，0表示完全透明，1表示不透明。默认为0.9
                strokeWeight: 3, //线条宽度，单位：像素
                // 折线样式还支持 'dashed'
                strokeStyle: "solid", //线样式，实线:solid，虚线:dashed
                // strokeStyle是dashed时有效
                // strokeDasharray: [10, 5],//勾勒形状轮廓的虚线和间隙的样式，此属性在strokeStyle 为dashed 时有效
                lineJoin: "round", //折线拐点的绘制样式，默认值为'miter'尖角，其他可选值：'round'圆角、'bevel'斜角
                lineCap: "round", //折线两端线帽的绘制样式，默认值为'butt'无头，其他可选值：'round'圆头、'square'方头
                zIndex: 50, //折线覆盖物的叠加顺序。默认叠加顺序，先添加的线在底层，后添加的线在上层。通过该属性可调整叠加顺序，使级别较高的折线覆盖物在上层显示默认zIndex：50、
              });
              // // 将折线添加至地图实例
              _this.map.add(_this.path);
            }
          }
        );
      } else {
        AMap.convertFrom(
          [_this.centerLon, _this.centerLat],
          "gps",
          function (status, result) {
            if (result.info === "ok") {
              _this.map.setZoomAndCenter(_this.map.getZoom(), [
                result.locations[0].KL,
                result.locations[0].kT,
              ]);
            }
          }
        );
      }
      // 创建一个 Marker 实例：

      //医院的位置
      // this.marker = new AMap.Marker({
      //   position: new AMap.LngLat(
      //     _this.mapList.Hospital.Longitude,
      //     _this.mapList.Hospital.Latitude
      //   ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //   // title: _this.mapList.Hospital.Name,
      // });

      //救护车的位置

      //  this.marker2 = new AMap.Marker({
      //     position: new AMap.LngLat(
      //       _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][0],
      //       _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][1]
      //     ), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      //     title: "救护车的位置",
      // icon:'',
      // icon: new AMap.Icon({
      //   image:
      //     "https://weiting-develop.oss-cn-shanghai.aliyuncs.com/Hospital/Image/390f1aa956b04f7e85f921429e1946d4_2021.png",
      //   size: new AMap.Size(40, 40), //图标所处区域大小
      //   imageSize: new AMap.Size(40, 40), //图标大小
      // }),
      // //  position: [  _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][0],  _this.mapList.PolylinePath[_this.mapList.PolylinePath.length - 1][1]],
      // anchor: "bottom-center",
      // offset: new AMap.Pixel(-5, -15), //(-10, -30)
      // });
      // 将创建的点标记添加到已有的地图实例：
      // _this.map.add(this.marker);
      // _this.map.add(this.marker2);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-left: 0;
}
.ma-5 {
  margin: 5px 5px 5px 9px;
}
.bg-F7 {
  background: #f7f7f7;
}
.setting-index {
  height: auto;
  .box {
    min-height: 390px;
    width: 32%;
    border-radius: 4px;
    position: relative;
    margin-top: 8px;
    .c-999 {
      width: 38%;
    }
    .c-000 {
      width: 90%;
    }
    .box-list {
      height: 315px;
      overflow-y: scroll;
    }
    .box-title {
      color: #5cb9da;
      padding: 5px 9px;
      border-bottom: 1px solid #5ab8d9;
    }
    .btn-add {
      width: 100%;
      height: 33px;
      background: #5cb9da;
      border-radius: 4px;
      position: absolute;
      bottom: 0;
    }
    .time {
      color: #a0a0a0;
    }
    .btn-detele {
      font-size: 10px;
      font-family: MicrosoftYaHei;
      color: #989696;
      line-height: 16px;
      background: #e6e6e6;
      width: 35px;
      height: 20px;
      border-radius: 5px;
    }
    .box-log {
      height: 220px;
      border-radius: 4px;
      .log-list {
        height: 180px;
        overflow-y: scroll;
      }
    }
    .map-box {
      width: 100%;
      height: 160px;
    }
  }
}
.popup {
  width: 450px;
  height: 300px;
  position: relative;
  top: 15%;
  left: 50%;
  transform: translate(-50%, 0);
  .popup-head {
    background: #f0f0f0;
    padding: 10px;
    color: #303039;
  }
  .popup-content {
    color: #303039;
    margin: 20px;
    input,
    textarea {
      font-size: 12px;
      border: none;
      background: transparent;
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(217, 217, 217, 1);
      border-radius: 4px;
      padding: 5px;
      width: 165px;
    }
    textarea {
      width: 280px;
      min-height: 20px;
    }
    .star {
      color: #eb2a26;
    }
    .btn-voice {
      border: 1px solid #5ab8d9;
      color: #5ab8d9;
      padding: 5px 10px;
      margin-left: 62px;
      margin-right: 5px;
      border-radius: 4px;
      user-select: none;
    }
    .btn-submit {
      width: 200px;
      margin: 20px auto;
      height: 33px;
      background: #5cb9da;
      border-radius: 4px;
    }
  }
}

.loading-box{
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>